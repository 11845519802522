import * as React from 'react'
import { useModalContext } from 'src/design-system'

export const SKILL_MODAL_ID = 'skill-modal'

export type SkillModalProps = {
  frameworkId?: string
  orgId?: string
  showControls?: boolean
  showMakeACopy?: boolean
  showRemoveFromTeam?: boolean
  skillId: string
  skillVariantId?: string
  source?: string
}

export const useOpenSkillModal = () => {
  const { openModal } = useModalContext()

  const openSkillModal = React.useCallback(
    (skillId: string) => {
      openModal(SKILL_MODAL_ID, {
        skillId,
      })
    },
    [openModal]
  )

  return {
    openSkillModal,
  }
}
